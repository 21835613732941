import {useState, useEffect, useContext} from "react";
import { GlobalContext } from '../globalState';

import { List } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { selectCallList } from "../query/selectCallList";
import { selectPatientListByFamily } from "../query/selectPatientListByFamily";

import * as commonFnc from '../bl/BL_common';

const style={
    patientId:{
        '& .MuiTypography-root': {
        fontWeight: 'bolder',
        textAlign: 'center',
        fontSize:'24px',
        color:'gray'
      },
      width:'95%',
      border:'2px solid lightgray',
      borderRadius:'3px',
      padding:'10px',
      margin:'5px auto',
      backgroundColor:'snow'
      
    },
    me:{
        backgroundColor:'#FFFFCC'
    }

}


/**
 * 施設ごとの呼出済み患者の一覧
 * selectedDeptgrCd 施設CD
 */
function PatientCallList(props) {

    const {selectedDeptgrCd, displayId} = props;
    const {loginUserInfo} = useContext(GlobalContext);  
        
    const [callViewList, setCallViewList] = useState(null)
    const [patientList, setPatientList] = useState([]);

    useEffect(()=>{
        
        // 家族の診察券番号一覧を取得
        const fetchFamilyPatientData = async () => {
            const data = await selectPatientListByFamily(loginUserInfo?.TREEID);
            setPatientList(data);
        }
        fetchFamilyPatientData();
        
        // 当日の呼出情報を取得する
        const fetchCallViewList = async ()=>{
            const callList = await selectCallList(selectedDeptgrCd, displayId);
            setCallViewList(callList);
        }
        fetchCallViewList();

        // 5秒ごとに再取得
        const intervalId = setInterval(() => {fetchCallViewList()}, 5000);

        // クリーンアップ関数でタイマーをクリア
        return () => clearInterval(intervalId);
  
    },[selectedDeptgrCd,displayId,loginUserInfo?.TREEID])

    /**
     * 
     */
    const isThisMe = (deptgrCd, patientId) =>{
        const patientInfo = patientList?.find(p=>p.PATIENT_ID === commonFnc.removeLeadingZeros(patientId) && p.DEPTGR_CD === deptgrCd);
        if(patientInfo){
            return true;
        }else{
            return false;
        }
    }

    return (
        <>
        {callViewList === null?
        <div class="LoaderContener"><div class="loader"></div></div>
        :
        <List>
            {callViewList.map((callView, index)=>(
            <ListItem
                sx={[style.patientId, isThisMe(selectedDeptgrCd, callView.PATIENT_ID)?style.me : false]}
            >
                <ListItemText
                    primary={commonFnc.removeLeadingZeros(callView.PATIENT_ID)}
                />
            </ListItem>
            ))}
        </List>
        }
        </>
    );
}

export default PatientCallList;
